<template>
  <div>
  <v-container >
    <h2 class="text-secondary mb-4 text-uppercase font-weight-bold">{{$store.state.bookingSteps.stepsData.step1.title}}</h2>
    <v-row v-if="loading" >
      <v-col
          v-for="index in 16"
          :cols="5"
          md="2"
          class="custom8cols">
        <v-skeleton-loader
            v-if="loading"
            :height="100"
            type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-item-group v-model="selected" class="stepper-padding" v-else @change="nextStep">
      <v-row>
        <v-col
            :cols="5"
            md="2"
            class="custom8cols"
            v-for="spe in specialities"
            :key="spe.id"
        >
          <v-item v-slot="{ active, toggle }">
            <v-card
                class="d-flex align-center"

                elevation="0"
                style="border-radius: 15px; overflow: hidden; height: 100%;box-shadow: none"
            >
              <v-scroll-y-transition>
                <div class="text-center  speciality-card" @click="toggle">
                  <img  :src="spe.image_url" alt="">
                  <h5>{{spe.name}}</h5>
                </div>
              </v-scroll-y-transition>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>

  </v-container>

    <div class="step-nav">
      <div class="d-flex align-center">
        <v-btn outlined  color="secondary" class="mx-5"  @click="$emit('cancel')">
          {{$store.state.bookingSteps.stepsData.cancelButtonLabel}}
        </v-btn>

        <v-btn  color="primary" class="mx-5"  :disabled="selected === null" @click="nextStep" elevation="0">
          {{$store.state.bookingSteps.stepsData.nextButtonLabel}}
        </v-btn>
      </div>
    </div>
  </div>

</template>

<script>
import API from "../../../../utils/dynamicAPI";

export default {
  name: "step1",
  data () {
    return {
      selected: null,
      specialities: [],
      loading: false
    }
  },
  async mounted() {
    this.loading = true

    if (this.$store.state.bookingSteps.stepsData.step1.request.type === 'get') {
      let res = await API.getAPI(this.$store.state.bookingSteps.stepsData.step1.request , this.$store.state.bookingSteps.stepsData.step1.request.headers)
      this.specialities = res.data.data
    }
    else if (this.$store.state.bookingSteps.stepsData.step1.request.type === 'post') {
      let res = await API.postAPI(this.$store.state.bookingSteps.stepsData.step1.request , this.$store.state.bookingSteps.stepsData.step1.request.headers)
      this.specialities = res.data.data
    }
    this.loading = false
  },
  methods: {
    nextStep () {
      this.$store.state.bookingSteps.selectedStepsData[this.$store.state.bookingSteps.stepsData.step1.selectedNameAttribute] = this.specialities[this.selected].name
      this.$store.state.bookingSteps.selectedStepsData[this.$store.state.bookingSteps.stepsData.step1.selectedIdAttribute] = this.specialities[this.selected].id
      this.$emit('nextStep')
    }
  }
}
</script>

<style lang="scss" scoped>
.speciality-card {
  img {
    border: none;
    border-radius: 0;
    background: transparent;
  }
}

.v-item--active {
  .speciality-card {
    background: var(--primaryColor);
    img {
      filter: brightness(0) invert(1) !important;
    }
  }
}
</style>
