<template>
  <div class="botter-user-input-field">
    <div v-if="data.prompt.type === 'file'">
      <image-uploader :url="fileUrl" @onImageUpload="updateImageUrl" :validation="false"/>
    </div>
    <v-form class="botter-inline-form" ref="userInput" v-else>
      <v-text-field
        outlined
        color="primary"
        v-model="input"
        height="40"
        :disabled="this.currentClass === 'botter-btn-clicked'"
      ></v-text-field>
      <button  :class="`botter-input-btn ${currentClass}`"  @click.prevent="sendInput">
        <span :class="`mdi ${currentIcon}`"></span>
      </button>
    </v-form>
  </div>
</template>

<script>
  import ImageUploader from "../../common/imageUploader";
  export default {
    name: "userInputField",
    components: {ImageUploader},
    props: {
      data: {
        type: Object,
        default: ()=> {
          return {}
        }
      }
    },
    data() {
      return {
        input: '',
        currentIcon: 'mdi-chevron-right',
        currentClass: '',
        fileUrl: ''
      }
    },
    mounted() {
      this.$store.dispatch('setInputDisabled', true)
    },
    methods: {
      sendInput() {
        if (this.currentClass !== 'botter-btn-clicked') {
          if (this.$refs.userInput.validate()) {
            this.currentIcon = 'mdi-spin mdi-loading'
            setTimeout(() => {
              this.currentIcon = 'mdi-checkbox-marked-circle'
              this.currentClass = 'botter-btn-clicked'
              this.$store.dispatch('pushMessage', {
                slug: 'text',
                payload: this.input,
                type: 'user',
                lastMessageGroup: true
              })
              this.$store.dispatch('setInputDisabled', false)
              this.$parent.$emit('postText',this.input)
            }, 500)
          }
        }
      },
      updateImageUrl(e) {
        this.fileUrl = e.url
        this.$parent.$emit('postFile',e)
        this.$store.dispatch('setInputDisabled', false)
      }
    },
    computed: {
      rules() {
        let pattern
        if (this.data.prompt.regex) {
          pattern = new RegExp(this.data.prompt.regex)
        }
        return [
          this.data.prompt.regex ? v => pattern.test(v) || this.data.prompt.errorMessage : true
        ]
      }
    }
  }
</script>

<style lang="scss">
.botter-user-input-field {
  margin-top: 10px;
}
  .botter-inline-form {
    display: flex;
    .v-input__slot {
      border-radius: 5px 0 0 5px !important;
    }
    .botter-input-btn {
      width:40px;
      height: 40px;
      cursor: pointer;
      display: flex;
      background: var(--primaryColor);
      align-items: center;
      justify-content: center;
      border-radius: 0 5px 5px 0;
      .mdi {
        font-size: 24px;
        color: white;
      }
    }
    .botter-btn-clicked {
      background: gray;
      cursor: initial !important;
      .mdi {

      }
    }
  }
</style>
